import { Badge, Footer } from "flowbite-react";
import styled from "styled-components";

const FooterStyled = styled(Footer)`
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 20px;
`;

function FooterComponent() {
  return (
    <FooterStyled container>
      <Footer.Copyright href="#" by="hoantien.top" year={2024} />
      <div className="flex space-x-6 sm:mt-0 sm:justify-center">
        <img
          src="/zalo_icon.png"
          alt="logo"
          className="h-8 w-auto sm:h-10"
          style={{ width: "20px", height: "20px" }}
        />
        <Badge color="success">0914778969</Badge>
      </div>
    </FooterStyled>
  );
}

export default FooterComponent;
